<template>
  <div class="News">
    <!-- <div class="banner">
      <div class="container">
      </div>
    </div> -->
    <div class="list pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>News </span>Center</h3>
        </div>
        <div>
          <el-row :gutter="24">
            <el-col :span="8">
              <div>
                <div class="_navList">
                  <div v-for="(item,index) in navList"
                       class="_lis"
                       :class="navIndex == index ? 'on':'' "
                       :key="index"
                       @click="onshowNews(item.id,index)">
                    <div class="imgbox">
                      <img src="@/assets/jiantou2.png"
                           alt="">
                    </div> {{item.title}}
                  </div>
                </div>
                <div class="tit">
                  Life Center
                </div>
                <router-link :to="'/live-in-china-detail/'+item.id"
                             class="lslis"
                             v-for="(item,index) in list"
                             :key="index">
                  <el-row :gutter="24">
                    <el-col :span="8">
                      <div class="imgbox">
                        <img :src="item.thumb"
                             alt="">
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <h3>{{item.title}}</h3>
                    </el-col>
                  </el-row>
                </router-link>
              </div>
            </el-col>
            <el-col :span="16">
              <div>
                <el-row :gutter="24">
                  <el-col :span="12"
                          v-for="(item,index) in news"
                          :key="index">
                    <router-link :to="'/news-detail/'+item.id"
                                 class="lis">
                      <div class="imgbox">
                        <img :src="item.thumb"
                             alt="">
                      </div>
                      <div class="text">
                        <div class="date">
                          <i class="el-icon-date"></i> {{item.updated_at}}
                        </div>
                        <div class="head">
                          {{item.title}}
                        </div>
                        <div class="cont">
                          {{item.description}}
                        </div>
                        <div class="more">READ MORE</div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
              </div>
              <el-pagination v-if="showpg"
                             background
                             layout="prev, pager, next"
                             @next-click="onNextPage"
                             @prev-click="onPrevPage"
                             @current-change="onCurrentChange"
                             :page-size="pageSize"
                             :total="total">
              </el-pagination>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axiosApi from "../api/api.js";
export default {
  data () {
    return {

    }
  },
  setup () {
    const navIndex = ref(0)
    const navList = ref()
    const news = ref()
    const list = ref()
    const total = ref(2)
    const pageSize = ref(12)
    const curPage = ref(0)
    const showpg = ref(false)
    const newsClassId = ref()
    const onshowNews = (id, index) => {
      showpg.value = false
      newsClassId.value = id
      axiosApi('news/' + id, {}, 'get').then(res => {
        showpg.value = true
        navIndex.value = index
        news.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onCurrentChange = (val) => {
      axiosApi(
        'news/' + newsClassId.value + '?page=' + val, {}, 'get'
      ).then((res) => {
        news.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onNextPage = (val) => {
      axiosApi(
        'news/' + newsClassId.value + '?page=' + val, {
      }, 'get'
      ).then((res) => {
        news.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      axiosApi(
        'news/' + newsClassId.value + '?page=' + val, {
      }, 'get'
      ).then((res) => {
        news.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    return {
      onCurrentChange,
      onshowNews,
      onNextPage,
      onPrevPage,
      navIndex,
      navList,
      news,
      list,
      total,
      pageSize,
      curPage,
      showpg
    }
  },
  beforeMount () {
    axiosApi('news/4', {}, 'get').then(res => {
      this.list = res.data.data
    })
    axiosApi(
      'cate/1', {}, 'get'
    ).then((res) => {
      this.navList = res.data
      return axiosApi(
        'news/' + res.data[0].id, {}, 'get'
      )
    }).then((res2) => {
      this.news = res2.data.data
      this.showpg = true
      this.total = res2.data.total
      this.pageSize = res2.data.per_page
    })

  },
  methods: {

  },
  watch: {
    curPage () {
      axiosApi(
        'university/recommend?page=' + this.curPage, {}, 'get'
      ).then((res) => {
        console.log(res)
        this.list = res.data.data
        this.total = res.data.total
        this.pageSize = res.data.per_page
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.list {
  .lis {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #dedede;
    .imgbox {
      width: 100%;
      height: 230px;
    }
    .text {
      margin-top: 10px;
      padding: 20px;
      .date {
        color: #0c3879;
        font-size: 14px;
      }
      .head {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        transition: ease 0.5s;
      }
      .cont {
        font-size: 14px;
        line-height: 24px;
        height: 48px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: ease 0.5s;
        margin-bottom: 15px;
      }
    }
    .more {
      text-decoration: underline;
      transition: ease 0.5s;
    }
    &:hover {
      .head {
        color: #0c3879;
      }
      .more {
        color: #ff7e00;
      }
    }
  }
}
._navList {
  ._lis {
    height: 40px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .imgbox {
      margin-right: 5px;
    }
    background: url(~@/assets/newsbg1.jpg) center center repeat-x;
    &:hover,
    &.on {
      background: url(~@/assets/newsbg2.jpg) center center repeat-x;
      color: #fff;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.lslis {
  display: block;
  margin-bottom: 20px;
  h3 {
    font-size: 14px;
  }
}
</style>